import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Partners = ({ bg, showTitle }) => {
  const getNumber = () => {
    const hours = new Date().getHours()
    const startNumber = 100
    // const endNumber = 350

    const calcNumber = Math.round((125 / 12) * hours + startNumber)

    return calcNumber.toLocaleString()
  }

  return (
    <div className={clsx(bg, 'partners-section alt')}>
      <div className="container">
        {/* {showTitle && (
          <div className="section-heading text-center">
            <h2>
              Today, we’ve helped <span className="counter">{getNumber()}</span> people
              find affordable Medicare insurance.
            </h2>
          </div>
        )} */}
        <ul className="partners-list">
          <li>
            <img src="/images/cigna.png" alt="Cigna" />
          </li>
          <li>
            <img src="/images/aetna.png" alt="Aetna" />
          </li>
          <li>
            <img
              src="/images/blue-cross-blue-shield.png"
              alt="Blue Cross / Blue Shield"
            />
          </li>
          <li>
            <img src="/images/mutual-of-omaha.png" alt="Mutual of Omaha" />
          </li>
          <li>
            <img src="/images/humana.png" alt="Humana" />
          </li>
        </ul>
      </div>
    </div>
  )
}

Partners.propTypes = {
  bg: PropTypes.string,
  showTitle: PropTypes.bool,
}

Partners.defaultProps = {
  bg: 'bg-light',
  showTitle: true,
}

export default Partners
